<template>
  <div class="columns is-marginless is-multiline">
    <div class="column is-12">
      <card>
        <template slot="header">
          <p class="card-header-title">Activities</p>
        </template>
        <template slot="body">
          <activities-table :user-id="userId" />
        </template>
      </card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Activities",
  components: {
    "activities-table": () => import("@shared/logs/_logsTable")
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    }
  }
};
</script>
